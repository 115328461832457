/* Nearyou.css */

.card {
    margin: 15px; /* Add margin to create spacing around each card */
    padding: 15px; /* Add padding to the card */
    border: 1px solid #ddd; /* Add a border for better visibility */
  }
  
  .card-img-top {
    width: 100%; /* Make the image fill the container */
    height: auto; /* Maintain the aspect ratio of the image */
  }
  
  .card-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Adjust as needed to control the spacing between cards */
  }
  
  /* Add the following to create a new row for each set of cards */
  .card-row {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px; /* Add margin to separate rows */
  }
  


  