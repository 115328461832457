
.ophthalmology-page {
    margin-top: 3cm; /* Adjust margin as needed */
  }
  
  /* Add a new class for the image rows */
  .image-row {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    flex-wrap: wrap;
    margin-top: 2cm;
  }
  
  /* Change the class name for the image cards */
  .new-image-card {
    position: relative;
    flex: 0 1 calc(33.333% - 20px);
    margin-bottom: 20px;
    border-radius: 20px;
    overflow: hidden;
    text-align: center; /* Center align the image and name */
  }
  
  /* Update styles for the images */
  .new-image-card img {
    width: 100%; /* Make the image fill its container */
    height: 5cm;
    width:6cm; /* Preserve aspect ratio */
    border: 5px solid black; /* Adjust border as needed */
  }
  
  /* Add styles for the image names within the image cards */
  .new-image-card .name {
    font-size: 50px !important; /* Make the font size bigger */
    font-weight: bold; /* Make the font bold */
    margin-top: 10px; /* Add some space above the name */
  }
  
  /* Update styles for the section heading */
  .section-heading {
    text-align: center;
    color: #555; /* Adjust color as needed */
  }
  
  /* Update styles for the section info */
  .section-info {
    text-align: center;
    color: #555;
    font-size: 20px;
  }
  
  /* Update styles for the page heading */
  .page-heading {
    text-align: center;
    color: #333; /* Adjust color as needed */
    margin-top: 3cm;
  }
  
  /* Update styles for the page info */
  .page-info {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    color: #777; /* Adjust color as needed */
  }
  
  /* Update styles for h1 */
  h1 {
    font-size: 24px;
    color: #333; /* Adjust color as needed */
  }
  
  /* Update styles for p */
  p {
    margin-bottom: 20px;
    color: #555; /* Adjust color as needed */
  }
  
  /* Update styles for the image container */
  .image-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Update styles for the image rows */
  .image-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  /* Update styles for the image cards */
  .new-image-card {
    flex-basis: calc(33.33% - 20px); /* Adjust width and margin */
  }
  
  /* Remove margin from the last image card in a row */
  .new-image-card:last-child {
    margin-right: 0;
  }
  
  @media screen and (max-width: 768px) {
    /* Adjust image card width for smaller screens */
    .new-image-card {
      flex-basis: calc(50% - 20px); /* Adjust width and margin */
    }
  }
  
  @media screen and (max-width: 480px) {
    /* Adjust image card width for even smaller screens */
    .new-image-card {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 20px; /* Adjust margin */
    }
  }
  /* ///////////////////////////// */


  /* Ophthalmology.css */

/* Add a new class for the wrapping div of the services page */
.ophthalmology-page {
    margin: 20px;
    margin-top: 3cm;
  }
  
  /* Add a new class for the image rows */
  .image-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  /* Change the class name for the image cards */
  .ImageCard {
    flex: 1;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
  }
  
  /* Update styles for the images */
  .ImageCard img {
    width: 100%;
    border-radius: 5px;
  }
  
  /* Add styles for the image names within the image cards */
  .ImageCard h3 {
    margin-top: 0;
    font-size: 25px;
  }
  
  /* Hover effect for image cards */
  .ImageCard:hover {
    transform: scale(1.05);
  }
  
  /* Center align the container */
  .ophthalmology-page {
    text-align: center;
  }
  
  /* Styles for headings and paragraphs */
  .page-heading {
    font-size: 30px;
    font-weight: 700;
    text-decoration: underline;
  }
  
  .page-info {
    font-size: 25px;
    font-weight: 600;
  }
  
  /* Additional styling as needed */

  

  /* Ophthalmology.css */

/* Add a new class for the wrapping div of the services page */
.ophthalmology-page {
    margin: 20px;
    margin-top: 3cm;
  }
  
  /* Add a new class for the image rows */
  .image-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  /* Change the class name for the image cards */
  .ImageCard {
    flex: 1;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
  }
  
  /* Update styles for the images */
  .ImageCard img {
    width: 100%;
    border-radius: 5px;
  }
  
  /* Add styles for the image names within the image cards */
  .ImageCard h3 {
    margin-top: 0;
    font-size: 25px;
  }
  
  /* Hover effect for image cards */
  .ImageCard:hover {
    transform: scale(1.05);
  }
  
  /* Center align the container */
  .ophthalmology-page {
    text-align: center;
  }
  
  /* Styles for headings */
  .page-heading {
    font-size: 30px;
    font-weight: 700;
    text-decoration: underline;
  }
  
  /* Styles for paragraphs */
  .page-info {
    font-size: 25px;
    font-weight: 600;
    max-width: 800px; /* Adjust max-width as needed */
    margin: 0 auto; /* Center align the paragraph */
  }
  
  /* Additional styling as needed */
  /* ///////////////////////////////////////// */


  /* Ophthalmology.css */

/* Add a new class for the wrapping div of the services page */
.ophthalmology-page {
  margin: 20px;
  margin-top: 3cm;
}

/* Add a new class for the image rows */
.image-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Change the class name for the image cards */
.ImageCard {
  flex: 1;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
}

/* Update styles for the images */
.ImageCard img {
  width: 100%;
  height: auto; /* Ensures images maintain aspect ratio */
  border-radius: 5px;
}

/* Add styles for the image names within the image cards */
.ImageCard h3 {
  margin-top: 0;
  font-size: 25px;
}

/* Hover effect for image cards */
.ImageCard:hover {
  transform: scale(1.05);
}

/* Center align the container */
.ophthalmology-page {
  text-align: center;
}

/* Styles for headings */
.page-heading {
  font-size: 30px;
  font-weight: 700;
  text-decoration: underline;
}

/* Styles for paragraphs */
.page-info {
  font-size: 25px;
  font-weight: 600;
  max-width: 800px; /* Adjust max-width as needed */
  margin: 0 auto; /* Center align the paragraph */
}

/* Additional styling as needed */

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .ImageCard {
    flex-basis: calc(50% - 10px); /* Adjust width for smaller screens */
  }
}

/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
  .ImageCard {
    flex-basis: 100%; /* Set full width for each image card on smaller screens */
    margin-right: 0; /* Remove margin on smaller screens */
    margin-bottom: 10px; /* Add some space between rows on smaller screens */
  }
}
