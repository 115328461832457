/* Contact.css */

.hlo {
  text-align: center;
  margin: 20px 0;
  margin-top: 3cm;
margin-bottom: 3cm;
  
  /* margin-bottom: 10cm !important; */
  
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 1cm !important;
  margin-bottom: 3cm;
  border-radius: 5cm;
  
}

.contact-info {
  background-color: #f5f5f5;
  padding: 20px;
  margin: 10px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
  width: 300px; /* Set a fixed width for larger screens */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.contact-info:hover {
  background-color: #e0e0e0;
}

.contact-info i {
  margin-bottom: 10px;
  font-size: 24px;
  color: #333;
}

.contact-info h2 {
  font-size: 18px;
  color: #333;
}

.contact-info p {
  font-size: 14px;
  color: #555;
  margin: 5px 0;
  /* color: aqua; */
}
.contact-info i {
  margin-bottom: 10px;
  font-size: 24px;
  /* color: aqua;  */
}

/* Responsive styles */
@media (max-width: 768px) {
  .contact-info {
    width: 100%; /* Full width for smaller screens */
  }
}
