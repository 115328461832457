





.homepage-container {
  width: 100%;
  min-height: 100vh; 
  margin-top: 2cm;
  position: relative; /* Added relative positioning */
}

.background-image {
  background-image: url('../Components/Assets/1c.jpg');
  background-size: cover;
  background-position: center;
  width: 100%; /* Adjusted width to 100% */
  height: 100%;
  position: absolute; /* Changed position to absolute */
  top: 0;
  left: 0;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  width: 80%; /* Adjusted width for better readability */
  max-width: 600px; /* Added max-width for better responsiveness on larger screens */
  margin: 0 auto; /* Center the text overlay horizontally */
}

.text-overlay h1 {
  font-size: 32px; /* Adjusted font size for smaller screens */
  margin-top: 3rem; /* Adjusted margin-top for smaller screens */
  font-weight: 700;
}

.text-overlay p {
  font-size: 18px; /* Adjusted font size for smaller screens */
}

@media (min-width: 768px) {
  /* Apply styles for screens larger than 768px (tablets and above) */
  .text-overlay h1 {
    font-size: 48px; /* Reset font size for larger screens */
    margin-top: 10cm !important; /* Adjusted margin-top for larger screens */
  }

  .text-overlay p {
    font-size: 24px; /* Reset font size for larger screens */
  }
}
