/* Navbar.css */

.navbar {
  background-color: #000a14;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  height: 70px; /* Adjust the height as needed */
}

.navbar-brand img {
  max-height: 100%; /* Maintain the logo's height within the navbar */
height: 1.5cm;
width: 2cm;
margin-left: 1cm;
}

.navbar-toggler-icon {
  border: 1px solid #020a12;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-nav {
  margin: auto; /* Center the navbar content horizontally */
  color: #000a14;
  text-align: center; /* Center the text within the navbar */
}

.nav-item {
  margin: 0 15px; /* Adjust spacing between nav items */
  color: #020a12;
}

.nav-link {
  color: #fff; /* Set the color to white */
  border: none; /* Remove border */
  padding: 15px; /* Adjust the padding for the navbar links */
  font-weight: 500; /* Apply font weight */
  text-decoration: none; /* Remove underline */
}

.nav-link:hover {
  text-decoration: none;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .navbar-nav {
    margin: 0;
  }

  .nav-item {
    margin: 0;
  }

  .navbar-collapse {
    justify-content: center;
  }

  .nav-link {
    display: block;
    margin: 10px 0;
  }

  .navbar-toggler {
    margin: 5px; /* Add some margin for better spacing */
  }
}

.nav-item:hover .nav-link::after {
  content: "";
  display: block;
  border-bottom: 3px solid #830d5e; /* Adjust the thickness and color of the underline */
  margin-top: 5px; /* Adjust the distance of the underline from the text */
}




/* styles.css */
/* styles.css */
.navbar-brand {
  font-family: Arial, sans-serif;
  color: #333; /* or any color you prefer */
  text-decoration: none;
  padding: 10px; /* Add padding */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Add text shadow */
  line-height: 1 !important; /* Adjust line height */
  margin-left: 2cm;
}

.navbar-brand .first-line {
  font-size: 25px; /* Font size for the first line */
}

.navbar-brand .second-line {
   font-size: 25px; 
}

.navbar-brand:hover {
  color: #ff4500; /* Change color on hover */
}






