/* Doctor.css */

.doctor-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 2cm;
}

.doctor-container {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  margin-top: 2cm;
}

.doctor-card {
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  width: 400px; /* Adjust the width as needed */
  background-color: #fff;
}

.doctor-card:hover {
  transform: scale(1.05);
}

.doctor-image {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
}

h2, p {
  padding: 10px;
  margin: 0;
}

.btn-explore-doctors {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-explore-doctors:hover {
  background-color: #0056b3;
}
.row doctor-container {
  margin-top: 2cm;
}
